'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')
const behaviorsMigrationHelper = require('../helpers/behaviorsMigrationHelper')

function fetchDesktopComponentsWithBehaviors(structure) {
    const flattenComponents = coreUtils.dataUtils.getAllCompsInStructure(structure, false)
    return _.pickBy(flattenComponents, 'behaviors')
}

function fetchCorrespondingMobileComponents(structure, desktopComponents) {
    const flattenMobileComponents = coreUtils.dataUtils.getAllCompsInStructure(structure, true)
    return _.pickBy(flattenMobileComponents, mobileComp => desktopComponents[mobileComp.id])
}

function setBehaviorsQuery(compStructure, dataItem, mobileCompStructure) {
    compStructure.behaviorQuery = dataItem.id
    if (mobileCompStructure) {
        mobileCompStructure.behaviorQuery = compStructure.behaviorQuery
    }
}

function moveBehaviorsFromStructureToData(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) {
    const pageData = _.get(pageJson, 'data')
    const pageStructure = _.get(pageJson, 'structure')
    pageData.behaviors_data = pageData.behaviors_data || {}
    const desktopComponents = fetchDesktopComponentsWithBehaviors(pageStructure)
    const mobileComponents = fetchCorrespondingMobileComponents(pageStructure, desktopComponents)
    const {uniqueIdGenerator} = magicObject.dataFixerUtils

    _.forEach(desktopComponents, compStructure => {
        const dataItem = behaviorsMigrationHelper.createBehaviorsDataItem(compStructure.behaviors, uniqueIdGenerator)
        setBehaviorsQuery(compStructure, dataItem, mobileComponents[compStructure.id])
        delete compStructure.behaviors
        _.set(pageData, ['behaviors_data', dataItem.id], dataItem)
    })
}

module.exports = {
    exec: moveBehaviorsFromStructureToData
}
