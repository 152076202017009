'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')

/**
 * For each component data of a blog feed app part in a given page JSON, changes the format of affected "read more"
 * value customizations from "" to "*" and removes the corresponding unaffected customizations if any (to avoid
 * issues with having multiple customizations that differ in value).
 */
const fixer = {
    exec(pageJson) {
        if (!pageJson.data) {
            return
        }

        _(pageJson.data.document_data)
            .filter(componentData => componentData.type === fixer.getAppPartType() &&
                _.includes(fixer.getBlogFeedAppPartNames(), componentData.appPartName)
            )
            .forEach(blogFeedAppPartComponentData => {
                const customizations = blogFeedAppPartComponentData.appLogicCustomizations
                _(customizations)
                    .compact() // Can contain null.
                    .filter(customization => customization.fieldId === fixer.getReadMoreValueCustomizationFieldId() && //eslint-disable-line lodash/prefer-matches
                        customization.key === fixer.getReadMoreValueCustomizationKey() &&
                        customization.format === '' &&
                        _.includes(fixer.getAffectedReadMoreValueCustomizationViews(), customization.view)
                    )
                    .forEach(affectedReadMoveValueCustomization => {
                        // Drop existing corresponding customizations if any with the format '*' to avoid issues
                        // with having multiple customizations that differ in value.
                        _.remove(customizations, {
                            fieldId: affectedReadMoveValueCustomization.fieldId,
                            format: '*',
                            key: affectedReadMoveValueCustomization.key,
                            view: affectedReadMoveValueCustomization.view
                        })

                        affectedReadMoveValueCustomization.format = '*'
                    })
            })
    },

    // The methods below are used for testing purposes (to simplify a number of test cases by replacing return
    // value).
    getAppPartType() {
        return 'AppPart'
    },
    getBlogFeedAppPartNames() {
        return [
            coreUtils.blogAppPartNames.FEED,
            coreUtils.blogAppPartNames.CUSTOM_FEED
        ]
    },
    getReadMoreValueCustomizationFieldId() {
        return 'ReadMoreBtn'
    },
    getReadMoreValueCustomizationKey() {
        return 'value'
    },
    getAffectedReadMoreValueCustomizationViews() {
        return [
            'MediaLeft',
            'MediaLeftPage',
            'MediaRight',
            'MediaRightPage',
            'MediaZigzag',
            'MediaZigzagPage',
            'Masonry',
            'MasonryPage'
        ]
    }
}

module.exports = fixer