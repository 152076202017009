'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')

const appPartMediaInnerCustomizationFormatFixer = {
    exec(pageJson) {
        if (!pageJson.data || !pageJson.data.document_data) {
            return
        }

        _(pageJson.data.document_data)
            .filter(component => appPartMediaInnerCustomizationFormatFixer.isAppPartType(component.type))
            .forEach(appPart => {
                _(appPart.appLogicCustomizations)
                    .compact()
                    .groupBy('view')
                    .filter((ignoredCustomizations, view) => coreUtils.appPartMediaInnerViewNameUtils.isMediaInnerViewName(view))
                    .forEach(mediaInnerCustomizations => {
                        _(mediaInnerCustomizations)
                            .groupBy('fieldId')
                            .filter(customizations => _.isEqual(_.map(customizations, 'format'), ['']))
                            .forEach(desktopCustomizations => {
                                _.forEach(desktopCustomizations, customization => {
                                    customization.format = '*'
                                })
                            })
                    })
            })
    },

    isAppPartType(type) {
        return type === 'AppPart'
    }
}

module.exports = appPartMediaInnerCustomizationFormatFixer