'use strict'
const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')

const MIGRATION_MAP = {
    'wysiwyg.viewer.components.BoxSlideShow': deleteChildlessComponent,
    'wysiwyg.viewer.components.StripContainerSlideShow': deleteChildlessComponent,
    'wysiwyg.viewer.components.Repeater': deleteChildlessComponent
}

const getMigrationMap = magicObject => {
    if (magicObject.isExperimentOpen('dm_keepChildlessAppWidget')) {
        return MIGRATION_MAP
    }
    return {
        ...MIGRATION_MAP,
        'platform.components.AppWidget': deleteChildlessComponent
    }
}


function deleteChildlessComponent(pageJson, cache, mobileView, comp, idGenerator, parentCompsArray) {
    const children = comp.components || []

    if (!children.length) {
        parentCompsArray.splice(parentCompsArray.indexOf(comp), 1)
    }
}

module.exports = {
    exec: (pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) => {
        const migrationMap = getMigrationMap(magicObject)
        return createHelperAndMigratePage(migrationMap)(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject)
    },
    comps: Object.keys(MIGRATION_MAP)
}
