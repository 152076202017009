'use strict'
const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')
const constants = require('@wix/document-services-json-schemas/dist/constants.json')
const {DATA_TYPES, COMP_DATA_QUERY_KEYS_WITH_STYLE, NAMESPACE_MAPPING} = constants
const affectedNameSpaces = [DATA_TYPES.theme, DATA_TYPES.design, DATA_TYPES.transformations, DATA_TYPES.transitions]

const stripLeadingHash = id => id && id[0] === '#' ? id.slice(1) : id

const isMobileCompQueryInvalid = (mobileComp, desktopComp, ns, fixAllDesign, designData) => {
    const queryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[ns]
    const mobileItemRef = mobileComp && mobileComp[queryKey]
    if (!desktopComp || !mobileItemRef || mobileItemRef === desktopComp[queryKey]) {
        return false
    }
    if (ns !== DATA_TYPES.design || mobileItemRef === `#${mobileComp.id}`) {
        return true
    }
    return fixAllDesign && !designData[stripLeadingHash(mobileComp[queryKey])]
}

const compHasWrongMobileSplit = (mobileComp, desktopComps, fixAllDesign, designData) => _.some(affectedNameSpaces, ns => {
    const compId = mobileComp.id
    const desktopComp = desktopComps[compId]
    return isMobileCompQueryInvalid(mobileComp, desktopComp, ns, fixAllDesign, designData)
})

const fix = (mobileComps, desktopComps, fixAllDesign, designData) => {
    _.forEach(mobileComps, (mobileComp, compId) => {
        const desktopComp = desktopComps[compId]
        _.forEach(affectedNameSpaces, ns => {
            if (isMobileCompQueryInvalid(mobileComp, desktopComp, ns, fixAllDesign, designData)) {
                const queryKey = COMP_DATA_QUERY_KEYS_WITH_STYLE[ns]
                mobileComp[queryKey] = desktopComp[queryKey]
            }
        })
    })
}

/**
 * The fixer handles components that have mobile split on namespaces that should not be split,
 * according the following algorithm:
 * 1. get all desktop components as flat map (comp id -> comp)
 * 2. get all corrupted mobile comps using the map from phase 1
 * 3. remove the corrupted query
 * the affected namespaces are: style, design, transitions, transformations
 *
 *
 */
module.exports = {
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewer, rendererModel, magicObject) {
        const {isExperimentOpen} = magicObject
        if (isExperimentOpen('dm_fixMobileSplitInVariantNs')) {
            try {
                const fixAllDesign = isExperimentOpen('dm_fixMobileSplitDesign')
                const designData = pageJson.data[NAMESPACE_MAPPING.design]
                const desktopComps = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, false, () => true)
                const shouldFixComponent = mobileComp => compHasWrongMobileSplit(mobileComp, desktopComps, fixAllDesign, designData)
                const mobileComps = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, true, shouldFixComponent)
                fix(mobileComps, desktopComps, fixAllDesign, designData)
            } catch (e) {
                //no bi in plugin fixers
                return false
            }
        }
    }
}
