'use strict'

const _ = require('lodash')
const coreUtils = require('@wix/santa-core-utils')
const {nonPublicDataQueries} = require('./publicNamespaces')

const eliminateBlackListedQueries = comp => {
    if (!_.isObject(comp)) {
        return
    }

    _.forEach(nonPublicDataQueries, query => delete comp[query])
}

module.exports = {
    exec: (pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) => {
        if (magicObject.isViewerMode) {
            const desktopComponents = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, false)
            const mobileComponents = coreUtils.dataUtils.getAllCompsInStructure(pageJson.structure, true)

            _.forEach(desktopComponents, eliminateBlackListedQueries)
            _.forEach(mobileComponents, eliminateBlackListedQueries)
        }
    }
}