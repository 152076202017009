'use strict'
const _ = require('lodash')
const {vectorImageConversionUtils} = require('@wix/santa-core-utils')
const {forEachStyle} = require('../helpers/themeDataUtils')

const compsToConvert = {
    'wysiwyg.viewer.components.VectorImageArt': vectorImageConversionUtils.vectorImageDefaults,
    'wysiwyg.viewer.components.VectorImageShape': vectorImageConversionUtils.vectorImageDefaults
}

const {convertStyle} = vectorImageConversionUtils
const {convertStructure} = vectorImageConversionUtils

function fixStyleItems(styles) {
    forEachStyle(styles, style => {
        const compDef = compsToConvert[style.componentClassName]
        if (compDef) {
            convertStyle(style, compDef)
        }
    })
}

function fixShapeComponents(components) {
    _.forEach(components, component => {
        const compDef = compsToConvert[component.componentType]
        if (compDef) {
            convertStructure(component, compDef)
        }
        if (component.components) {
            fixShapeComponents(component.components)
        }
    })
}

module.exports = {
    exec(pageJson/*, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel*/) {
        const styles = pageJson.data.theme_data
        const components = pageJson.structure.components || pageJson.structure.children
        const {mobileComponents} = pageJson.structure
        if (!_.isEmpty(styles)) {
            fixStyleItems(styles)
        }

        if (!_.isEmpty(components)) {
            fixShapeComponents(components)
        }

        if (!_.isEmpty(mobileComponents)) {
            fixShapeComponents(mobileComponents)
        }
    }
}
